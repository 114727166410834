import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Aries.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => { 
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Aries Relationship
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/aries"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Aries</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Aries Relationship</h4>
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Aries Relationship</h1>
                <h2 className="text-sm md:text-base ml-4">Mar 21 - Apr 19</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Aries Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Aries Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Aries Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-love");
                  }}>
                  <h5 className="font-semibold text-black">Aries Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-nature");
                  }}>
                  <h5 className="font-semibold text-black">Aries Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-man");
                  }}>
                  <h5 className="font-semibold text-black">Aries Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Aries Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-traits");
                  }}>
                  <h5 className="font-semibold text-black">Aries Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-facts");
                  }}>
                  <h5 className="font-semibold text-black">Aries Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
             <p className="playfair text-black text-xl md:text-3xl">Aries Relationship</p><br/>
             Known as social magnets, Aries has a childlike innocence that makes them irresistible to others. Trust is a big issue for you, so when trust is broken, it's almost impossible to undo the damage in an Aries relationship. will make you an ally. Never get bored. You are extroverted and adventurous, so you always bring good vibes and variety to the world of your loved ones.<br/>
             <br></br>
             You may become jealous and possessive of those close to you. Unlike others, you usually get straight into their feelings. You express your expectations and feelings openly and boldly. <br/>
             <br></br>
             <p className="playfair text-black text-xl md:text-3xl">Aries as a lover</p><br/>
             Rams are known to be flirtatious when it comes to romance and aggressively pursue their loved ones. When you are attracted to someone, you are very passionate about that person. You'll face your loved one's feelings head-on instead of banging around the bush. You are a determined, emotional, and passionate lover. You are prone to throwing tantrums at times and showing temper towards your loved partner. But if you recognize it, you will explain your undying feelings to your partner. <br/>
             <br></br>
             Get along well with Aries, If you're doing it and want to impress them, you can't afford to be monotonous. You have to keep inspiring them to do new things. Bodhi says you have to live up to their energy and expectations. The Ram family loves adventure and wants to control their relationships. Building relationships with them is not easy, but once committed, they are usually honest and lifelong loyal. You must show them your love and care. They expect the same level of integrity, honesty, and loyalty from you. If you cheat or lie to them, they will feel isolated and will be out of the relationship forever. Get insight into your love life with the free love horoscope report <br/>
             <br></br>
             <p className="playfair text-black text-xl md:text-3xl">Aries as a Colleague</p><br/>
             You will prove to be an enthusiastic, motivated, and helpful colleague. You like to take care of the work yourself, but you reach out whenever someone needs it. They volunteered first and advanced new projects. It can be difficult to distinguish between viable and worthwhile projects. You are a natural leader and can be bossy at times. Your attitude is not appreciated by other employees. You make decisions on impulse, and sometimes you have to pay a lot for them. You were born with boundless energy and a pioneering spirit, making it difficult for other employees to keep up with you. Your fiery optimism and positivity are contagious. Ultimately, you can give more than you can if you put your passion into it.<br/>
             <br></br>
             <p className="playfair text-black text-xl md:text-3xl">Aries as a friend</p><br/>
             You are very loyal and loyal to your friends and expect the same from them.  You effortlessly support your friends but expect gratitude from them. You are flexible and get along easily with people of different natures. Therefore, your friends are diverse and completely different. They also get along with polar opposite personalities. <br/>
             <br></br>
             Because of this, all friends have the strongest bond with you. Bodhi says that you were born with a bundle of energy, so your friends will not fit in well with you. You either need to slow down a  bit or stretch your muscles to get things done. You are sensitive and emotional, but your friends generally don't confide in you about their emotional side. When you build a strong bond with someone, you'll be honest and forever trustworthy.<br/>
             <br></br>
             If you have many friends with different personalities, your biggest challenge is making everyone happy. You often underestimate your friends and throw tantrums. If your friend argues with you, you will continue to argue with him to prove him wrong. After all, all friendships are unique. <br/>
             <br></br>
             However, according to astrology, some sun signs go well with other zodiac signs.<br/> So who are your best friends and worst friends? <br/>
             <br></br>
             <p className="playfair text-black text-xl md:text-3xl">Aries as  Boss</p><br/>
             As a boss, the Rams can be a very tough taskmaster. You are energetic, enthusiastic, impulsive, and never hesitate to take risks. You are a natural leader and know how to bring out the best in your people. You are a champion and it doesn't take long to make a decision. You want to finish your work early. We always expect our employees to put all their energy into their assigned tasks and complete them quickly. <br/>
             <br></br>
             They take a no-nonsense approach and only appreciate the best work. They will criticize you openly if you don't finish your work on time or if you make a lot of mistakes at work. You're not completely devoid of compassion, says Bodhi. You can understand your employees and recognize their problems with ease. You will do anything to support them to get out of a difficult situation. They will also support you and give you 100% effort. You are highly innovative and introduce new technologies to make your employees' jobs easier and faster. <br/>
             <br></br>
             It will give employees a lot of freedom in terms of working style and time management. Only desired results matter. To understand the ins and outs of your relationship with your boss: Get fully personalized reports and handwritten compatibility between you and your boss.<br/>
             <br></br>
             <p className="playfair text-black text-xl md:text-3xl">Aries Father</p><br/>
             You are fun-loving and care deeply about your children and family. You enjoy the time you spend raising your children. You are doing very well in your responsibilities as a father. You enjoy caring for children and feel no responsibility or stress. You are generally a controlling father and overprotective of your children. You have some control over your children, but at the same time, give them plenty of space to show and develop their talents. You are a perfectionist, do your best in everything you do, and expect the same from your children. <br/>
             <br></br>
             Encourage your child to play various games, and participate in sports and physical activities. They are firm believers in balancing learning and play. You want them to learn all the good values. You are sociable and very kind, but at the same time, you make it clear that you are a father first and a friend second. The biggest advantage of having an Aries father is that you never have a dull moment with him. He is open to having fun family time and planning adventurous family outings. The downside is that you impose your likes and dislikes directly on your child. <br/>
             <br></br>
             <p className="playfair text-black text-xl md:text-3xl">Aries as Mother</p><br/>
             You are affectionate, strict, controlling, and overprotective of children. You are a super mom and raise your kids to be super kids. You love to spoon-feed your children, but you are also strict when you find them distracted from their goals and objectives in life. The biggest advantage of having an Aries mother is that she does everything for her children and cares deeply for them. <br/>
             <br></br>
             She wipes away children's tears, shrugs them off and makes them cry, expresses their emotions, and even listens to their problems. The only downside is that they impose strict rules and expect their children to follow them. All parents raise their children in different ways. Learn about parenting styles by zodiac sign<br/>
             <br></br>
             <p className="playfair text-black text-xl md:text-3xl">Aries in childhood</p><br/>
             Rams as a child respects and loves her parents. You love to get a lot of attention from your parents and are very picky about your demands. You are rarely wild and rebellious. You have a passionate, creative, and restless approach to life. You are very strong in your choices and life goals. You will be stubborn in following your chosen path and career despite your parents' objections. increase. Sharing toys and favorite foods are hard. You are optimistic, cheerful, self-motivated, and like to try everything. <br/>
             <br></br>
             You want to take the lead in your science, career, or other activities. You love participating in competitive exams and sports. You may be a natural leader, a sports team captain, or a class supervisor. You love to dominate your siblings and friends. It's a really difficult time for your parents, siblings, and friends to make you realize your shortcomings. You're on your feet, you're on the move, and you're involved in everything that interests you. You are active 24/7. You get angry easily, but that anger quickly turns into happiness and joy. You're allowed to be stubborn in competition and stick to your needs and demands. Don't settle until your parents agree, says Bodhi. Your tantrums and temper often anger your parents and make them intolerant and unacceptable. You are destined for great things in life and will bring glory to your family. Most of them are very attached to their parents, and the older their parents are, the more likely these children are to meet their parents' needs.<br/>
             <br></br>
             <p className="playfair text-black text-xl md:text-3xl">Aries as  Husband</p><br/>
             <br></br>
             <p className="font-bold text-orange-500 inline-block mr-2">Best marriage match:</p>Gemini, Leo, Sagittarius, Aquarius  
             <br></br>
             You may have trouble adjusting to your new role as a husband in addition to your current role. That doesn't mean you don't deal with it. Once you've developed a strong bond with your partner and feel that your relationship has merit, you'll quickly start enjoying your new role. You can be a great husband and many girls dream of having a husband like you. You are a very ambitious, emotional, brave, and kind-hearted husband. You usually have a positive attitude in your relationship with your spouse, and she appreciates that. <br/>
             <br></br>
             You are a loyal husband and your wife can blindly trust you. You will marry someone smart, beautiful, and, above all, kind-hearted. Your impatience can be the source of problems in your marriage. You seek spontaneous results in all areas of life. That means you can miss out on a lot. You are gentle, dynamic, and impressionable, but can easily get frustrated when things don't go your way. If she objects or suggests something, you will offend her. This attitude can put a strain on your relationship. Your personal goals are important to you and this can lead to ignorance of your wife. <br/>
             <br></br>
             At this point, she may be traumatized. Know  your marriage prospects and marriage-related issues in 2024 with the help of Aries Marriage, Family and Children Horoscope 2024 <br/>
             <br></br>
             <p className="playfair text-black text-xl md:text-3xl">Aries as wife</p><br/>
             <br></br>
             <p className="font-bold text-orange-500 inline-block mr-2">Best Marriage Match:</p>Gemini, Leo, Sagittarius, Aquarius Men <br/>
             <br></br>
             You are an amazing, smart, adorable, and independent wife. You are beautiful with a brain. They demonstrate the qualities of an experienced wife who knows all aspects of a man well and brings out the best in him. You won't get married until you find a partner you like. You are one of the most progressive women, ambitious and fascinated by new ideas and challenges. You need a husband who is ambitious and determined to succeed in life.  <br/>
             <br></br>
             You can exhibit some dominant qualities in your relationship. You are very active and have the best work-life balance. You are noble, sincere, loyal, and kind to your husband. Find a way out of every problem and never complain about anything. You have strong opinions and tend to brag about your accomplishments in public. You are possessive and want plenty of attention from your partner. If they don't, you're jealous. You don't like controlling partners. You feel lucky when your man is as passionate and exclusive as you are. As long as you believe in yourself and are always open to suggestions and changes from other family members. To know more about it, check our daily horoscope absolutely free.<br/>
             <br/>
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
